.edit-btn {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.35em;
	text-transform: uppercase;
	margin: 5px 1px;
	padding: 0.7vw;
	border-radius: 3px;
	transition: all .15s linear;
	user-select: none;
	outline: none;
	background: transparent;
	border: 2px solid #00b0ba !important;
	color: #00b0ba;
	opacity: 1;
}

.photo-icon-btn {
	font-size: 32px;
	background: #00b0ba;
	color: #fff;
	line-height: 1.5626rem;
	border: 0;
	border-radius: 50%;
	padding-top: 5px;
	padding-bottom: 3px;
	cursor: pointer;
}
.animate-input {
    animation-name: tremor-input;
    animation-duration: 2000ms;
}

@keyframes tremor-input {
    0%, 100% {
        box-shadow: 1px 2px 10px -1px #000000;
        height: 40px;
    }
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
