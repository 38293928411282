.sheet {
    background: #fff;
}
.sheetHeader {
    color: #00b0ba;
}

.weekDay {
    background: #f4f4f4;
    font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
}

.vacationWeekDay {
    background: #f4f4f4;
    font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
}
.textSize {
    font-size: .8vw !important;
    border-bottom: 1px solid #DEE2E6;
}

.greenBackground {
    background: #ccc;
}

.workDay {
    background: #00DF6C;
    font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
}

.dayOff {
    background: #FAC84A;
    font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
}