.workDaysDropDown {
    color: rgba(0, 0, 0, .4)!important;
    cursor: pointer;
    transition: 0.1s all;
}
.workDaysDropDown:hover {
    color: rgba(0, 0, 0, .4)!important;
}
.workDaysDropDown:hover {
    color: #6F655B;
}
.workDaysDropDown i {
    transition: 0.1s all;
}
.workDaysDropDown:hover i {
    color: rgba(0, 0, 0, .4)!important;
}
.workDaysDropDown i {
    color: rgba(0, 0, 0, 0.3)!important;
}

.help-list {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.help-item {
    padding: 11px 0 0 !important;
    margin-top: 0 !important;
}

.help-item p {
    margin-left: 1.2em !important;
    font-size: 11px !important;
    line-height: 20px !important;
}
.sidebar-link {
    margin-top: 0 !important;
}
.sidebar-logo {
    width: 159px;
    height: 65px;
    display: inline-block;
}
.logo-image-animated {
    width: 100%;
    height: 100%;
    background: url('../../assets/img/logoAttractor.png') center center no-repeat;
    background-size: 100%;
    display: inline-block;
}
.logo-text {
    width: 59%;
    height: 100%;
    display: inline-block;
    background: url('../../assets/img/attractor-software-logotype-text.1bc32307.png') 50% 50% no-repeat;
    background-size: contain;
}
.sidebar-wrapper{
    font-size: 12px;
}
.help-link{
    transition: 0.2s all;
}
.drop-link{
   margin-left: 0.5em;
    font-size: 12px;
    line-height: 1px;
    text-transform: uppercase;
}