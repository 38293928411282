* {
    box-sizing: border-box;
}

.calendar {
    width: 100%;
    background-color: #fff;
    padding: 0 30px 30px;
    border-radius: 1%;
}

.calendarHeaderTitle {
    font-weight: 700
}

.dayItem {
    width: calc(100% / 7);
    height: 6.5rem;
    border-bottom: 1px solid #dee2e6;
}

.weekDayItem {
    width: calc(100% / 7);
    border-bottom: 1px solid #dee2e6;
}

.weekendDayItem {
    background: rgb(244, 244, 244);
}

.weekendDaySelect {
    background: #e0e0e0!important;
}

.calendarTable {
    width: 100%;
}

.calendarHeader {
    font-weight: 700;
    list-style-type: none;
}

.weekItem {
    /*margin: 0 auto 0 0;*/
    list-style-type: none;
}

.dayNumber {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.currentDay {
    background: #00bbff;
    color: #fff;
    border-radius: 50%;
}

.currentWeekendDay {
    background: #e0e0e0;
    color: #343a40;
}
.font-size{
    font-size: 25px;
}
.closed-period{
    background: #9A9A9A;
}
/*font-weight: 700;*/