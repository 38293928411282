.tooltip [class$="arrow"] {
  opacity: 0;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    color: #6c757d;
    display: block;
    width: 100%;
    background: #f5f5f5;
    border: none;
    padding: 3px;
}