table {
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif !important;
}

.table-contacts-mini {
    display: none;
}

table > thead > tr > th {
    font-size: 12px !important;
    color: #00b0ba !important;

}

* {
    box-sizing: border-box;
}

@media (max-width: 650px) {

    .table-contacts-mini {
        display: block !important;
    }

    .table-contacts {
        display: none;
    }

    .td-table-mini {
        width: 50% !important;
    }
}

@media (max-width: 580px) {
    .btn-contacts {
        width: 100%;
        margin: 5px 0;
    }

    .card-body {
        padding-left: 5px;
        padding-right: 5px;
    }
}

tbody tr td {
    vertical-align: middle !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}

input:focus {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.remove_user {
    background: #f5c6cb;
    border-color: inherit;
}

.hov:hover {
    transform: translate(-13px);
    box-shadow: 0 6px 7px -5px rgba(0, 0, 0, 0.50);
    transition: .3s;
    border: none;
}

.table thead th {
    border-bottom: 2px solid #474A4A;
    border-top: 2px solid #474A4A;
}

.table-contacts-mini {
    display: none;
    padding-right: 0;
    padding-left: 0;
}

.input-contacts {
    background: #dee2e6;
}

.input-contacts:focus {
    background: #dee2e6;
}

.icon_search {
    position: absolute;
    top: 25%;
    right: 30px;
    width: 26px;
    height: 26px;
    background-size: cover;
}

.contacts-wrap {
    margin-top: 75px;
    min-height: calc(100vh - 144px)!important;
}

.card-body > .table-contacts {
    padding-left: 30px;
}

.all-btn {
    background: #00B0BA !important;
}

.btn-active-filter {
    background: rgb(234, 234, 234) !important;
    color: rgb(128, 128, 128) !important;
}

.btn-vacation {
    background: rgb(135, 135, 135) !important;
}

.btn-fired {
    background: rgb(140, 110, 100) !important;
}

.btn-contacts {
    padding: 0.5vw 1vw !important;
}

.hov-effect:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 7px -5px rgba(0, 0, 0, 0.50);
    transition: .3s;
    border: none;
}



